import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import catcute from '../../assets/paper-texture.jpg';
import './index.scss';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function QuiltedImageList() {
return (
    <div className="showcase2">
    <ImageList
        variant="quilted"
        cols={4}
        rowHeight={"60%"}
        gap={10}
    >
        {itemData.map((item) => (
            <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                <img
                    {...srcset(item.img, 121, item.rows, item.cols)}
                    alt={item.title}
                    loading="lazy"
                    className="page-item"
                    sx={{ 
                        '&:hover': {
                            opacity: 0.8,
                            cursor: 'pointer',
                        },
                    }}
                />
                <span className='thumbnail-title'>Coming soon!</span>
            </ImageListItem>
        ))}
    </ImageList>
    </div>
);
}

const itemData = [
  {
    img: catcute,
    title: 'Breakfast',
    rows: 2,
    cols: 2,
  },
  {
    img: catcute,
    title: 'Burger',
  },
  {
    img: catcute,
    title: 'Camera',
  },
  {
    img: catcute,
    title: 'Coffee',
    cols: 2,
  },
  {
    img: catcute,
    title: 'Hats',
    cols: 2,
  },
  {
    img: catcute,
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
  },
  {
    img: catcute,
    title: 'Basketball',
  },
  {
    img: catcute,
    title: 'Fern',
  },
  {
    img: catcute,
    title: 'Mushrooms',
    rows: 2,
    cols: 2,
  },
  {
    img: catcute,
    title: 'Tomato basil',
  },
  {
    img: catcute,
    title: 'Sea star',
  },
  {
    img: catcute,
    title: 'Bike',
    cols: 2,
  },
];