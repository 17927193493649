import React from 'react';
import './index.scss';

function Footer() {
    return (
        <footer className='Footer'>
            <div className='footer-content'>
                <p>&copy; 2024 Paige Masters Illustration</p>
                {/* <nav>
                    <ul>
                        <li><a href='/me'>About</a></li>
                        <li><a href='/contact'>Contact</a></li>
                        <li><a href='/socials'>Social Media</a></li>
                    </ul>
                </nav> */}
            </div>
        </footer>
    );
}

export default Footer;