import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './index.scss';

function Header() {
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    }
    return (
        <div className='wrapper'>
            <div className={`mobile-nav ${showMenu ? 'show' : ''}`}>
                <div className='close-btn' onClick={toggleMenu}>
                    <p className='btn-text'>menu</p>
                </div>
                <ul>
                    <li><NavLink exact to='/' activeClassName="active" onClick={toggleMenu}>work</NavLink></li>
                    <li><NavLink to="/illustration" activeClassName="active" onClick={toggleMenu}>illustration</NavLink></li>
                    <li><NavLink to="/me" activeClassName="active" onClick={toggleMenu}>me</NavLink></li>
                </ul>
            </div>
            <header className="Header">
                <div className='logo'>
                    <h1 className='logo-title'>paige masters</h1>
                </div>
                <button className={`menu-btn ${showMenu ? 'show' : ''}`} onClick={toggleMenu}>
                    <p className='btn-text'>
                        menu
                    </p>
                </button>
                <nav className={`header-nav ${showMenu ? 'show' : ''}`}>
                    <ul>
                        <li><NavLink exact to='/' activeClassName="active">work</NavLink></li>
                        <li><NavLink to="/illustration" activeClassName="active">illustration</NavLink></li>
                        <li><NavLink to="/me" activeClassName="active">me</NavLink></li>
                    </ul>
                </nav>
            </header>
        </div>
    );
}

export default Header;