import React from "react";
import './index.scss';

function MePage() {
    return (
        <body className="aboutBody">
            <div className="aboutDesc">
                <h1>About Paige Masters</h1>
                <p>Paige Masters is a freelance illustrator, artist, and designer from Leicester. She currently lives in Maidenhead, Berkshire, and is studying Graphic Design and Illustration at UCA in Farnham, Surrey.</p>
                <p>Her work focuses on creating beautiful imagery, putting her unique style into everyday life and nature. She is always interested in working on fantasy stories in any medium.</p>
                <p>She is currently creating her final project for her year at UCA, and works on passion projects whenever she can. She loves the colour brown, moscato, green tea, and cartoons.</p>
                <p>For professional opportunities, more information, or other opportunities please contact me at <b>paige@paigemasters.co.uk</b></p>
                <p><a href="/">Resume</a> / <a href="/">Twitter</a> / <a href="/">Tumblr</a> / <a href="/">Behance</a> / <a href="/">LinkedIn</a> / <a href="/">Instagram</a></p>
            </div>
            <div className="aboutImg">
                <img src={require ("../../assets/youngpij.jpg")} alt="Young Pij"></img>
            </div>
        </body>
    )
}

export default MePage;