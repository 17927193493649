import React from "react";
import './index.scss';

import cat_gif from '../../assets/cat-cute.gif';

function IllustrationPage() {
    return (
        <body className="illustration-page">
            <h1 className="page-title">Under construction!</h1>
            <p>Illustration Page - [WIP]</p>
            <img className="cat-gif" src={cat_gif} alt="cat gif"></img>
        </body>
    )
}

export default IllustrationPage;