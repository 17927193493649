import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.scss';
import WorkPage from './pages/WorkPage';
import MePage from './pages/MePage';
import IllustrationPage from './pages/IllustrationPage';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div className="App">
        <div className='app-wrapper'>
          <Header />
          <Routes>
            <Route exact path="/" element={<WorkPage />} />
            <Route path="/illustration" element={<IllustrationPage />} />
            <Route path="/me" element={<MePage />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
