import React from "react";
import Showcase2 from "../../components/Showcase2";
import './index.scss';

function WorkPage() {
    return (
        <body>
            <h1 className="page-title">Coming soon!</h1>
            <div className="page-showcase">
                <Showcase2 />
            </div>
        </body>
    )
}

export default WorkPage;